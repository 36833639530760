import {BigNumber} from "@ethersproject/bignumber";
import {serializeError} from "eth-rpc-errors";
import {calculateGasMargin} from "./index";

export const estimateGas = async (
  contract,
  method,
  args = [],
  overrideParams = {},
  withSafetyMargin = true,
  withErrorHandler = true,
) => {
  if(!contract) return BigNumber.from('0');

  const estimation = contract.estimateGas[method]
  try {
    const estimatedGas = await estimation(...args, {
      ...overrideParams
    });

    if(withSafetyMargin) return calculateGasMargin(estimatedGas);
    return estimatedGas;
  } catch(e) {
    if(withErrorHandler) return Promise.reject(serializeError(e));
    return BigNumber.from('0')
  }
}

export const callMethod = async (
  contract,
  method,
  args
) => {
  try {
    return await contract[method](...args);
  } catch (e) {
    return serializeError(e);
  }
}

export const sendTransaction = async (
  contract,
  methodName,
  args = [],
  overrideParams = {},
  onError,
  onSuccess
) => {
  try {
    const estimatedGas = await estimateGas(contract, methodName, args, overrideParams, true, true);
    const method = contract[methodName]

    try {
      const res = await method(...args, {
        ...overrideParams,
        gasLimit: estimatedGas
      })

      if(onSuccess) return onSuccess(res)
      return true;
    } catch (err) {
      if(onError) return onError(serializeError(err));
      return false;
    }
  } catch (err) {
    if(onError) return onError(err);
    return false;
  }
}
