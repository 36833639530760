import { useModal } from '../../context/modal';
import TermsModal from '../../components/TermsModal';
import './style.scss';
import Logo from '../../assets/images/logo.svg';

export default function Footer() {
  const [onPresentTermsModal] = useModal(<TermsModal />)

  return (
    <footer className="footer-container">
      <div className="section-container">
        <div className="footer">
          <a href="https://www.dioneprotocol.com/">
            <div className="logo-footer">
              <img
                src={Logo}
                alt="logo"
              />
            </div>
            <div>Powered by Dione Protocol</div>
          </a>
          <div className='footer__copyright'>Dione Protocol © 2023</div>
          <div className='footer__nav'>
            <a href='https://form.jotform.com/230754540145451' target="_blank" rel="noreferrer noopener">Report an Issue</a>
            <div className='footer__separator' style={{ color: 'rgba(255, 255, 255, 0.2)' }}>|</div>
            <a href={process.env.PUBLIC_URL + '/docs/audit.pdf'} target="_blank" rel="noreferrer noopener">Staking Audit Report</a>
            <div className='footer__separator' style={{ color: 'rgba(255, 255, 255, 0.2)' }}>|</div>
            <a href="#" onClick={onPresentTermsModal}>Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
