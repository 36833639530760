import './style.scss';
import Logo from '../../assets/images/logo.svg';
import { useModal } from '../../context/modal';
import { Store } from '../../Providers';
import WalletModal from '../../components/WalletModal';
import {useContext} from 'react';
import useAuth from "../../hooks/useAuth";
import {useActiveChainId} from "../../hooks/useActiveChainId";
import {useAccount} from "wagmi";

export default function Navbar() {
  const { global, setGlobal } = useContext(Store);
  const [onPresentWalletModal] = useModal(<WalletModal />)

  const { isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { address: account, isConnected } = useAccount()

  const HandleWalletModal = () => {
    if (!global.connected && !account) {
      onPresentWalletModal()
    } else {
      setGlobal({ connected: '' });
      logout();
    }
  };

  return (
    <nav className="nav-container">
      <div className="section-container">
        <div className="nav">
          <a href="https://www.dioneprotocol.com/" className="logo">
            <img
              src={Logo}
              alt="logo"
            />
          </a>
          <button
            className="nav-btn"
            onClick={HandleWalletModal}
          >
            {isWrongNetwork ? "Wrong Network" : (account && isConnected) ? `Disconnect (${account?.slice(0, 6)}...${account?.slice(-4)})` : `Connect Wallet`}
          </button>
        </div>
      </div>
    </nav>
  );
}
