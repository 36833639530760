import { Modal } from "../../context/modal";
import './style.scss';

const termsModalData = [
  'Terms of Service for Dione Protocol LLC Cryptocurrency Staking Contract',
  'These terms of service (the "Agreement") are entered into between Dione Protocol LLC ("Dione Protocol", "we", "us", “the project” , or "our") and you ("User", "you", or "your") in connection with your use of our cryptocurrency staking service (the “Service").',
  'By using the Service, you agree to be bound by this Agreement, as well as any additional terms and conditions applicable to specific features or services that we may make available to you from time to time. If you do not agree to the terms of this Agreement, you may not use the Service.',
  '1. Description of the Service\nThe Service allows you to stake certain cryptocurrencies on the Dione Protocol platform in exchange for rewards. By staking cryptocurrencies, you help secure the Dione Protocol network and enable the processing of transactions on the blockchain. The specific terms and conditions of the staking process are set forth on the Dione Protocol website.',
  '2. Risks\nStaking cryptocurrencies involves risks, including the risk of losing all or part of your staked cryptocurrencies. You should carefully consider the risks associated with staking before participating in the Service. Dione Protocol makes no representations or warranties regarding the suitability or profitability of staking, and we are not responsible for any losses you may incur as a result of participating in the Service. No rewards are guaranteed through the Service.',
  '3. Eligibility\nTo use the Service, you must be at least 18 years old and otherwise legally eligible to participate in cryptocurrency staking. You must also comply with all applicable laws and regulations regarding cryptocurrency and other financial transactions. Dione Protocol may, in its sole discretion, refuse to provide the Service to anyone at any time and for any reason.',
  '4. Fees\nDione Protocol may charge fees for the use of the Service. These fees are subject to change at any time without notice. By using the Service, you agree to pay any fees charged by Dione Protocol.',
  '5. Rewards\nDione Protocol will provide rewards to Users who stake cryptocurrencies on the Dione Protocol platform. The specific rewards and the terms and conditions of the reward program are set forth on the Dione Protocol website. No rewards are guaranteed through the Service.',
  '6. Termination\nDione Protocol may terminate this Agreement and your access to the Service at any time for any reason. Upon termination, you will no longer be able to stake cryptocurrencies or receive rewards through the Service.',
  '7. Disclaimer of Warranties\nTHE SERVICE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. DIONE PROTOCOL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON- INFRINGEMENT, AND ANY WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE.',
  '8. Limitation of Liability\nIN NO EVENT SHALL DIONE PROTOCOL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SERVICE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR WHETHER DIONE PROTOCOL WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL DIONE PROTOCOL\'S AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SERVICE EXCEED THE TOTAL AMOUNT OF FEES PAID BY YOU TO DIONE PROTOCOL DURING THE SIX- MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO SUCH LIABILITY.',
  '9. Indemnification\nYou agree to indemnify, defend, and hold harmless Dione Protocol, its affiliates, officers, directors, employees, agents, licensors, and suppliers, from and against any claims, actions, suits, or proceedings, as well as any and all losses, liabilities, damages, costs, and expenses (including reasonable attorneys\' fees) arising out of or in connection with your use of the Service, your violation of this Agreement, or your violation of any rights of any third party.',
  '10. Governing Law and Jurisdiction\nThis Agreement shall be governed by and construed in accordance with the laws of the state of Delaware, without giving effect to any choice of law or conflict of law provisions. Any dispute arising out of or in connection with this Agreement or the use of the Service shall be brought exclusively in the state or federal courts located in Wilmington, Delaware.',
  '11. Entire Agreement\nThis Agreement constitutes the entire agreement between you and Dione Protocol with respect to the use of the Service, and supersedes all prior or contemporaneous communications and proposals (whether oral, written, or electronic) between you and Dione Protocol. If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.'
];

export default function TermsModal({ onDismiss }) {

  return (
    <Modal onDismiss={onDismiss} style={{ maxWidth: 890 }} title="Terms of Service">
      <div className="initial-modal">
        {termsModalData.map((item, index) => (
          <div
            className="initial-modal-item"
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
    </Modal>
  );
}
