import {BigNumber} from "@ethersproject/bignumber";
import {isAddress} from "./addressHelper";
import {AddressZero} from "@ethersproject/constants";
import {Contract} from "@ethersproject/contracts";

export function calculateGasMargin(value, margin = 1000) {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(margin))).div(BigNumber.from(10000))
}

export function getContract(address, ABI, signer) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer)
}
