import { TrustWalletConnector } from '../wagmi/connectors'
import { bscTestnet, goerli, mainnet } from 'wagmi/chains'
import { configureChains, createClient } from 'wagmi'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { WalletConnectLegacyConnector } from 'wagmi/connectors/walletConnectLegacy'
import { LedgerConnector } from 'wagmi/connectors/ledger'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const CHAINS = [mainnet]

const getNodeURL = (networkName) => {
  let host = null

  switch (networkName) {
    case 'homestead':
      if (process.env.REACT_APP_INFURA_API_ETH) {
        host = process.env.REACT_APP_INFURA_API_ETH
      }
      break
    case 'goerli':
      if (process.env.REACT_APP_INFURA_API_GOERLI) {
        host = process.env.REACT_APP_INFURA_API_GOERLI
      }
      break
    default:
      host = 'https://data-seed-prebsc-2-s2.binance.org:8545'
  }

  if (!host) {
    return null
  }

  const url = host
  return {
    http: url,
    webSocket: url.replace(/^https/i, 'wss')
  }
}

export const { provider, chains } = configureChains(CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (!!process.env.REACT_APP_ENV && chain.id === mainnet.id) {
        return { http: process.env.REACT_APP_INFURA_API_ETH }
      }
      if (chain.id === bscTestnet.id) {
        return { http: 'https://data-seed-prebsc-2-s2.binance.org:8545/' }
      }

      return getNodeURL(chain.network) || { http: chain.rpcUrls.default.http[0] }
    },
  }),
])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Dione Staking Program',
    appLogoUrl: 'https://dioneprotocol.com/img/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectLegacyConnector({
  chains,
  options: {
    qrcode: true,
    projectId: '09f6ca9db393f41df579e39281baf231'
  },
})

export const walletConnectV2Connector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
    version: '2',
    projectId: '8544385d6a3112c8a1fe2d9ee0d80bf5'
  },
})

export const walletConnectNoQrCodeConnector = new WalletConnectLegacyConnector({
  chains,
  options: {
    qrcode: false,
    projectId: '09f6ca9db393f41df579e39281baf231'
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

const ledgerConnector = new LedgerConnector({
  chains,
})


export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    walletConnectV2Connector,
    ledgerConnector,
    trustWalletConnector,
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId) => chains.find((c) => c.id === chainId)?.testnet)
