import React from 'react';
import { SWRConfig } from "swr"
import { Provider } from 'react-redux';
import { WagmiProvider } from "./wagmi"
import { client } from './utils/wagmi'
import { useState } from 'react';
import {initialReduxState, persistor, useStore} from './state'
import {fetchStatusMiddleware} from "./hooks/useSWRContract";
import {PersistGate} from "redux-persist/integration/react";
import { ModalProvider } from './context/modal';

export const Store = React.createContext();

const Providers = ({ children }) => {
    const store = useStore(initialReduxState)

    const [global, setGlobal] = useState({
      connected: ''
    });

    return (
        <WagmiProvider client={client}>
            <Provider store={store}>
                <SWRConfig
                    value={{
                        use: [fetchStatusMiddleware],
                    }}
                >
                  <PersistGate loading={null} persistor={persistor}>
                    <Store.Provider value={{ global, setGlobal }}>
                        <ModalProvider>
                            {children}
                        </ModalProvider>
                    </Store.Provider>
                  </PersistGate>
                </SWRConfig>
            </Provider>
        </WagmiProvider>
    )
}

export default Providers;
