import { getTrustWalletProvider } from '../wagmi/connectors'
import { metaMaskConnector, walletConnectNoQrCodeConnector } from '../utils/wagmi'

import MetamaskIcon from '../assets/images/metamask.svg';
import SafePalIcon from '../assets/images/safePal.svg';
import TrustWalletIcon from '../assets/images/trustWallet.svg';
import WalletConnectIcon from '../assets/images/walletConnect.svg';
import DioneIcon from '../assets/images/dioneIcon.png';
import InjectedIcon from '../assets/images/injected.png';

export const ConnectorNames = {
  MetaMask: 'metaMask',
  Injected: 'injected',
  WalletConnect: 'walletConnectLegacy',
  WalletConnectV2: 'walletConnect',
  WalletLink: 'coinbaseWallet',
  Ledger: 'ledger',
  TrustWallet: 'trustWallet',
}

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t))

const createQrCode = (chainId, connect) => async () => {
  connect({ connector: walletConnectNoQrCodeConnector, chainId })

  // wait for WalletConnect to setup in order to get the uri
  await delay(100)
  const { uri } = ((await walletConnectNoQrCodeConnector.getProvider())).connector

  return uri
}

const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if (window.ethereum?.isMetaMask) {
    return true
  }

  return !!window.ethereum?.providers?.some((p) => p.isMetaMask);
}

const walletsConfig = ({
  chainId,
  connect,
}) => {
  const qrCode = createQrCode(chainId, connect)
  return [
    {
      id: 'metamask',
      title: 'Metamask',
      icon: MetamaskIcon,
      get installed() {
        return isMetamaskInstalled() && metaMaskConnector.ready
      },
      connectorId: ConnectorNames.MetaMask,
      qrCode,
    },
    {
      id: 'dionewallet',
      title: 'Dione Wallet',
      icon: DioneIcon,
      connectorId: ConnectorNames.WalletConnect,
    },
    {
      id: 'trust',
      title: 'Trust Wallet',
      icon: TrustWalletIcon,
      connectorId: ConnectorNames.WalletConnectV2,
    },
    {
      id: 'walletconnect',
      title: 'WalletConnect',
      icon: WalletConnectIcon,
      connectorId: ConnectorNames.WalletConnectV2,
    },
    {
      id: 'ledger',
      title: 'Ledger',
      icon: SafePalIcon,
      connectorId: ConnectorNames.Ledger,
    },
  ]
}

export const createWallets = (chainId, connect) => {
  const hasInjected = typeof window !== 'undefined' && !window.ethereum
  const config = walletsConfig({ chainId, connect })
  return hasInjected && config.some((c) => c.installed && c.connectorId === ConnectorNames.Injected)
    ? config // add injected icon if none of injected type wallets installed
    : [
        ...config,
        {
          id: 'injected',
          title: 'Injected',
          connectorId: ConnectorNames.Injected,
          icon: InjectedIcon,
          installed: typeof window !== 'undefined' && Boolean(window.ethereum),
        },
      ]
}
