import {useMemo} from "react";
import {useActiveChainId} from "./useActiveChainId";
import {getContract} from "../utils";
import {getERC20Contract} from "../utils/contractHelper";
import {getDioneAddress, getMulticallAddress, getStakingAddress} from "../utils/addressHelper";

import ERC20_ABI from '../constants/abi/ERC20.json'
import multiCallAbi from '../constants/abi/Multicall.json'
import StakingAbi from '../constants/abi/DioneStaking.json'
import {useProviderOrSigner} from "./useProviderOrSigner";

export const useERC20 = (address, withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getERC20Contract(address, providerOrSigner), [address, providerOrSigner])
}

export function useMulticallContract() {
  const { chainId } = useActiveChainId()
  return useContract(getMulticallAddress(chainId), multiCallAbi, false)
}

export function useContract(
  address,
  ABI,
  withSignerIfPossible = true,
) {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)

  const canReturnContract = useMemo(() => address && ABI && providerOrSigner, [address, ABI, providerOrSigner])

  return useMemo(() => {
    if (!canReturnContract) return null
    try {
      return getContract(address, ABI, providerOrSigner)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, providerOrSigner, canReturnContract])
}

export function useTokenContract(tokenAddress, withSignerIfPossible) {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useDioneContract(withSignerIfPossible) {
  const { chainId } = useActiveChainId();
  return useContract(getDioneAddress(chainId), ERC20_ABI, withSignerIfPossible)
}

export function useStakingContract(withSignerIfPossible) {
  const { chainId } = useActiveChainId();
  return useContract(getStakingAddress(chainId), StakingAbi, withSignerIfPossible)
}
