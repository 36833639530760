
import { CHAIN_QUERY_NAME } from '../constants/chains'
import replaceBrowserHistory from '../utils/replaceBrowserHistory'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  ConnectorAlreadyConnectedError,
  ConnectorNotFoundError,
  SwitchChainError,
  SwitchChainNotSupportedError,
  useConnect,
  useDisconnect,
  useNetwork,
} from 'wagmi'
import { useActiveChainId } from './useActiveChainId'
import { useSessionChainId } from './useSessionChainId'

const useAuth = () => {
  const dispatch = useDispatch()
  const { connectAsync, connectors } = useConnect()
  const { chain } = useNetwork()
  const { disconnectAsync } = useDisconnect()
  const { chainId } = useActiveChainId()
  const [, setSessionChainId] = useSessionChainId()

  const login = useCallback(
    async (connectorID) => {
      const findConnector = connectors.find((c) => c.id === connectorID)
      try {
        const connected = await connectAsync({ connector: findConnector, chainId })
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chain', CHAIN_QUERY_NAME[connected.chain.id])
          setSessionChainId(connected.chain.id)
        }
        return connected
      } catch (error) {
        if(error instanceof ConnectorAlreadyConnectedError) {
          return undefined;
        }
        if (error instanceof ConnectorNotFoundError) {
          throw new Error('WalletConnect not found')
        }
        if (error instanceof SwitchChainNotSupportedError || error instanceof SwitchChainError) {
          throw new Error('Unable to switch network. Please try it on your wallet')
        }
      }
      return undefined
    },
    [connectors, connectAsync, chainId, setSessionChainId],
  )

  const logout = useCallback(async () => {
    try {
      await disconnectAsync()
    } catch (error) {
      console.error(error)
    }
  }, [disconnectAsync, dispatch, chain?.id])

  return { login, logout }
}

export default useAuth
