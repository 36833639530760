import { Modal } from "../../context/modal";
import './style.scss';

import {useEffect, useState, useContext, useMemo} from 'react';
import ButtonPrimary from '../../components/ButtonPrimary';
import { Store } from '../../Providers';
import {createWallets} from "../../constants/wallet";
import {ConnectorNotFoundError, SwitchChainError, SwitchChainNotSupportedError, useConnect} from "wagmi";
import {useActiveChainId} from "../../hooks/useActiveChainId";
import useAuth from "../../hooks/useAuth";
import {atom, useAtom} from "jotai";
import {isMobile} from "react-device-detect";
import { logger } from "ethers";

const errorAtom = atom('')
const selectedWalletAtom = atom(null)
export function useSelectedWallet() {
  return useAtom(selectedWalletAtom)
}

export const walletLocalStorageKey = 'wallet'
const lastUsedWalletNameAtom = atom('')
lastUsedWalletNameAtom.onMount = (set) => {
  const preferred = localStorage?.getItem(walletLocalStorageKey)
  if (preferred) {
    set(preferred)
  }
}

function sortWallets(wallets, lastUsedWalletName) {
  const sorted = [...wallets].sort((a, b) => {
    if (a.installed === b.installed) return 0
    return a.installed === true ? -1 : 1
  })

  if (!lastUsedWalletName) {
    return sorted
  }
  const foundLastUsedWallet = wallets.find((w) => w.title === lastUsedWalletName)
  if (!foundLastUsedWallet) return sorted
  return [foundLastUsedWallet, ...sorted.filter((w) => w.id !== foundLastUsedWallet.id)]
}

export default function WalletModal({ onDismiss }) {
  const { setGlobal } = useContext(Store);
  const { chainId } = useActiveChainId()
  const { login } = useAuth()
  const { connectAsync } = useConnect()

  const _wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync]);

  const [lastUsedWalletName] = useAtom(lastUsedWalletNameAtom)
  const wallets = useMemo(() => sortWallets(_wallets, lastUsedWalletName), [_wallets, lastUsedWalletName])


  const installedWallets = wallets.filter((w) => w.installed)
  const mobileWallets = wallets.filter((w) => {
    if (installedWallets.length) {
      return w.installed
    }
    return w.installed !== false || w.deepLink
  })
  const wallets_ = wallets.filter((w) => {
    return w.installed !== false || (!w.installed && (w.guide || w.downloadLink || w.qrCode))
  })

  const walletsToShow = isMobile ? mobileWallets : wallets_;

  const [selected, setSelected] = useSelectedWallet()
  const [error, setError] = useAtom(errorAtom)
  const [qrCode, setQrCode] = useState(undefined)
  const [loading, setLoading] = useState(false);

  const connectWallet = (wallet) => {
    setSelected(wallet)
    setError('')
    if (wallet.installed !== false) {
      setLoading(true);
      login(wallet.connectorId)
        .then((v) => {
          setLoading(false);
          if (v) {
            localStorage.setItem(walletLocalStorageKey, wallet.title)
            setGlobal({ connected: selected });
            onDismiss();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err instanceof ConnectorNotFoundError) {
            setError('no provider found')
          } else if (error instanceof SwitchChainNotSupportedError || error instanceof SwitchChainError) {
            setError(err.message)
          } else {
            setError('Error connecting, please authorize wallet to access.')
          }
        })
    }
  }

  useEffect(() => {
    if(lastUsedWalletName && chainId) {
      setGlobal({ connected: wallets.find(wallet => wallet.title === lastUsedWalletName)})
    }
  }, [chainId, lastUsedWalletName])

  return (
    <Modal onDismiss={onDismiss} style={{ maxWidth: 420 }}>
      <div className="wallet-modal">
        {loading || error ? (
          <div className={`wallet-modal-toast ${loading ? 'wallet-modal-toast__loading' : error ? 'wallet-modal-toast__error' : null}`}>
            {
              loading 
                ? `Connecting to ${selected.title}...`
                : error
                  ? error
                  : ''
            }
          </div>
        ) : null}
        <div className="wallet-container">
          {walletsToShow.map((wallet, index) => {
            return (
              <div
                className={`wallet-moda-item ${selected === wallet ? 'wallet-moda-item-selected' : null}`}
                key={index}
                onClick={() => {
                  connectWallet(wallet)
                  if (wallet.deepLink && wallet.installed === false) {
                    window.open(wallet.deepLink)
                  }
                  setQrCode(undefined)
                  if (wallet.qrCode) {
                    wallet.qrCode().then((uri) => {
                      console.log(uri);
                      setQrCode(uri)
                    })
                      .catch(e => console.log(e));
                  }
                }}
              >
                <div>
                  <img src={wallet.icon} alt={wallet.title} />
                </div>
                <div>{wallet.title}</div>
              </div>
            )
          })}
        </div>
        <div
          className="modal-btn"
          onClick={onDismiss}
        >
          <ButtonPrimary>Connect Wallet</ButtonPrimary>
        </div>
      </div>
    </Modal>
  );
}
