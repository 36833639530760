import { useCallback } from 'react'
import get from 'lodash/get'
import { BigNumber } from '@ethersproject/bignumber';

export function useCallWithGasPrice() {

  const callWithGasPrice = useCallback(
    async (
      contract,
      methodName,
      methodArgs = [],
      overrides = null,
  ) => {
    const contractMethod = get(contract, methodName)
    const hasManualValueOverride = overrides?.value;

    const tx = await contractMethod(
      ...methodArgs,
      hasManualValueOverride ? { ...overrides } : { ...overrides, value: BigNumber.from('0') },
    )


    return tx
  },
  [],
)

  return { callWithGasPrice }
}
