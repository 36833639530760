import { useAccount } from 'wagmi'
import { FAST_INTERVAL } from '../constants'
import addresses from '../constants/addresses'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { useMemo } from 'react'
import useSWR from 'swr'
import { rpcProvider } from '../utils/providers'
import { useWeb3React } from '../wagmi'
import { useTokenContract } from './useContract'
import { useSWRContract } from './useSWRContract'

const BIG_ZERO = BigNumber.from('0');

const useTokenBalance = (tokenAddress, forceMainnet) => {
  const { address: account } = useAccount()

  const contract = useTokenContract(tokenAddress, false)

  const key = useMemo(
    () =>
      account
        ? {
          contract: forceMainnet ? contract.connect(rpcProvider) : contract,
          methodName: 'balanceOf',
          params: [account],
        }
        : null,
    [account, contract, forceMainnet],
  )

  const { data, status, ...rest } = useSWRContract(key, {
    refreshInterval: FAST_INTERVAL,
  })

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? BigNumber.from(data.toString()) : BIG_ZERO,
  }
}

export const useGetETHBalance = () => {
  const { address: account } = useAccount()
  const { status, data, mutate } = useSWR([account, 'ethBalance'], async () => {
    return rpcProvider.getBalance(account)
  })

  return { balance: data || Zero, fetchStatus: status, refresh: mutate }
}

export const useGetDioneBalance = () => {
  const { chainId } = useWeb3React()
  const { balance, fetchStatus } = useTokenBalance(addresses.dione[chainId] || addresses.dione[1], false)

  return { balance: BigNumber.from(balance.toString()), fetchStatus }
}

export default useTokenBalance
