import { useEffect } from 'react'
import { CHAIN_QUERY_NAME } from '../constants/chains'
import { useDispatch } from 'react-redux'
import useActiveWeb3React from './useActiveWeb3React'
import { useSessionChainId } from './useSessionChainId'
import replaceBrowserHistory from "../utils/replaceBrowserHistory";

export const useAccountEventListener = () => {
  const { account, chainId, connector } = useActiveWeb3React()
  const [, setSessionChainId] = useSessionChainId()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account && connector) {
      const handleUpdateEvent = (e) => {
        if (e?.chain?.id && !(e?.chain?.unsupported ?? false)) {
          replaceBrowserHistory('chain', CHAIN_QUERY_NAME[e.chain.id])
          setSessionChainId(e.chain.id)
        }
      }

      connector.addListener('change', handleUpdateEvent)

      return () => {
        connector.removeListener('change', handleUpdateEvent)
      }
    }
    return undefined
  }, [account, chainId, dispatch, connector, setSessionChainId])
}
