import {useEffect, useState, useMemo} from 'react';
import ButtonPrimary from '../../components/ButtonPrimary';
import Card from '../../components/Card';
import './style.scss';
import SandClock from '../../assets/icons/sand-clock.svg';
import Reward from '../../assets/icons/reward.svg';
import Tax from '../../assets/icons/tax.svg';
import Unstake from '../../assets/icons/unstake.svg';
import Community from '../../assets/icons/community.svg';
import TotalReward from '../../assets/icons/total-reward.svg';
import WalletModal from '../../components/WalletModal';
import TermsModal from '../../components/TermsModal';
import Principle from '../../assets/icons/principle.svg';
import Alarm from '../../assets/icons/alarm.svg';
import {useAccount} from "wagmi";
import {useActiveChainId} from "../../hooks/useActiveChainId";
import {useDeposit, useStakingStatus, useUserInfo, useWithdraw} from "../../hooks/contracts/useStaking";
import {useGetDioneBalance} from "../../hooks/useTokenBalance";
import toast from "react-hot-toast";
import {BigNumber} from "@ethersproject/bignumber";
import {ethers} from "ethers";
import moment from 'moment';
import { useModal } from '../../context/modal';
import AmountModal from '../../components/AmountModal';
import NotesModal, { notesLocalStorageKey } from '../../components/NotesModal';

const cardsData = [
  {
    icon: SandClock,
    title: 'Minimum Staking Time',
  },
  {
    icon: Reward,
    title: 'Rewards after end of Staking term',
  },
  {
    icon: Tax,
    title: 'Tax Reimbursement after end of Staking term',
  },
  {
    icon: Unstake,
    title: 'Early Unstake Penalty',
  },
  {
    icon: Community,
    title: 'Community Penalty Rewards Pool',
  },
  {
    icon: TotalReward,
    title: 'Total Rewards after end of Staking term',
  },
];

const walletData = [
  'Staking term',
  'Up to 8%',
  '2%',
  '5% - 7%',
  'Variable',
  'Up to 8% of Principle',
];

const stakeCardData = [
  {
    icon: Principle,
    title: 'Staking Duration',
    id: 'stakeDuration'
  },
  {
    icon: Reward,
    title: 'APY Rewards Accrued',
    id: 'rewardAccrued'
  },
  {
    icon: Community,
    title: 'Community Penalty Rewards Accrued',
    id: 'penaltyAccrued'
  },
  {
    icon: SandClock,
    title: 'Staked Amount',
    id: 'stakedAmount'
  },
  {
    icon: TotalReward,
    title: 'Total Potential Rewards',
    id: 'totalPenalty'
  },
  {
    icon: Unstake,
    title: 'Early Unstake Penalty',
    id: 'earlyPenalty'
  },
];


export default function Main() {
  const [checkStake, setCheckStake] = useState(false);
  const [checkUnstake, setCheckUnStake] = useState(false);
  const { chainId, isWrongNetwork } = useActiveChainId()
  const { address: account } = useAccount()

  const [onPresentTermsModal] = useModal(<TermsModal />)
  const [onPresentNotesModal] = useModal(<NotesModal />)
  const [onPresentWalletModal] = useModal(<WalletModal />)

  useEffect(() => {
    const notesShow = localStorage?.getItem(notesLocalStorageKey)
    if(!notesShow) onPresentNotesModal();
  }, [])

  const dioneBalance = useGetDioneBalance()


  const { isWithdrawable, isFinished, isStarted } = useStakingStatus();
  const { userInfo, penalty, pendingReward, pendingPenalty, penaltyTier } = useUserInfo();
  const [depositLoading, deposit] = useDeposit();
  const [withdrawLoading, withdraw] = useWithdraw();
  const [onPresentAmountModal] = useModal(<AmountModal onStake={deposit} balance={dioneBalance.balance} loading={depositLoading} />)

  const stakeData = useMemo(() => {
    return [
      userInfo?.stakingTimestamp ? moment(userInfo?.stakingTimestamp, 'X') : '-',
      pendingReward ? ethers.utils.formatUnits(pendingReward, '9') + ' Dione' : '-',
      pendingPenalty ? ethers.utils.formatUnits(pendingPenalty, '9') + " Dione" : '-',
      userInfo?.amount ? ethers.utils.formatUnits(userInfo?.amount, '9') + ' Dione' : '-',
      userInfo?.amount ? ethers.utils.formatUnits(userInfo?.amount?.mul('8').div('100'), '9') + ' Dione' : '-',
      penalty && penaltyTier ? `${ethers.utils.formatUnits(penaltyTier, '2')}% or ${ethers.utils.formatUnits(penalty, '9')} Dione` : '5%-7%'
    ]
  }, [userInfo, penalty, pendingReward, penaltyTier, pendingPenalty])


  const penaltyTierPercent = useMemo(() => {
    return penaltyTier ? `${ethers.utils.formatUnits(penaltyTier, '2')}%` : '5%-7%';
  }, [penaltyTier])


  const stake = useMemo(() => {
    if(!userInfo || !account) return false;

    return userInfo?.amount?.gt('0');
  }, [userInfo, account])

  const handleStake = async () => {
    if (!(checkStake || checkUnstake)) {
      toast.error('Please accept terms of service first!');
      return;
    }

    if(dioneBalance.balance.eq(BigNumber.from('0'))) {
      toast.error('Insufficient balance');
      return;
    }

    onPresentAmountModal();
  };

  const HandleUnstakeStatus = async () => {
    if (!checkUnstake && !isWithdrawable) {
      toast.error('Please accept terms of service first!');
      return;
    }

    if(userInfo?.amount?.isZero()) {
      toast.error('Insufficient Stake amount');
      return;
    }
    await withdraw([], {});
  };

  return (
    <main className="main-container">
      <div className="bg-shape1"/>
      <div className="bg-shape2"/>
      <div className="bg-shape3"/>
      <div className="bg-shape4"/>
      <div className="section-container">
        <div className="main-title">
          Welcome to the Dione Staking Program
        </div>
        {stake && (
          <div className="stake-subtitle">
            You began staking {stakeData?.[0]?.fromNow()}
          </div>
        )}

        <div className="cards-container">
          {!stake
            ? cardsData.map((item, index) => (
                <Card
                  item={item}
                  key={index}
                  value={walletData.filter((item, i) => {
                    return index === i
                  })}
                />
              ))
            : stakeCardData.map((item, index) => {
              return (
                <Card
                  item={item}
                  key={index}
                  value={index === 0 ? stakeData[index]?.fromNow(true) : stakeData[index]}
                />
              )
            })}
        </div>
        {stake && (
          <div className="stake-note">
            Note: The above does not include an additional 2% tax
            reimbursement or additional earned penalty pool rewards.
          </div>
        )}

        <div className="stake-section">
          {stake && (
            <div className="stake-alarm-container">
              <div className="stake-alarm">
                <div>
                  <img src={Alarm} alt="alarm" />
                </div>
                <div>
                  If you unstaked today, you would not realize any APY
                  rewards, you would not get a 2% tax reimbursement,
                  you will not be able to participate in community
                  rewards pool, you will be charged a {penaltyTierPercent} early
                  unstaking penalty and you would not have your Dione
                  auto-migrated to the Dione Blockchain Native Coin.
                </div>
              </div>
            </div>
          )}
          {stake ? (isWithdrawable ? null : (
            <label
              htmlFor="unstake"
              className="checkbox-container"
            >
              <input
                type="checkbox"
                id="unstake"
                name="unstake"
                value="unstake"
                checked={checkUnstake}
                onChange={(e) => setCheckUnStake(e.target.checked)}
              />
              I understand the <span className='highlighted' onClick={onPresentTermsModal}>Terms of Service</span> and want to unstake anyway.
              <span className="checkmark"/>
            </label>
          )) : account ? (
            <label
              htmlFor="stake"
              className="checkbox-container"
            >
              <input
                type="checkbox"
                id="stake"
                name="stake"
                value="stake"
                checked={checkStake}
                onChange={(e) => setCheckStake(e.target.checked)}
              />
              I agree to the <span className='highlighted' onClick={onPresentTermsModal}>Terms of Service</span> and want to stake my Dione
              <span className="checkmark"/>
            </label>
          ) : <div className='spacer'/>}

          {stake && !isWithdrawable && (
            <div className="stake-note">
              Note: A {penaltyTierPercent} penalty will be applied if you unstake
              before the staking end-date and you will not be eligible
              for a 2% tax reimbursement.
            </div>
          )}

          {!account ? (
            <div
              className="btn"
              onClick={onPresentWalletModal}
            >
              <ButtonPrimary>Connect your wallet</ButtonPrimary>
            </div>
          ) : isWrongNetwork ? (
            <div
              className="btn"
              onClick={onPresentWalletModal}
            >
              <ButtonPrimary>Wrong Network</ButtonPrimary>
            </div>
          ) : stake ? (
            <div
              className="btn unstake-btn"
            >
              <ButtonPrimary
                onClick={handleStake}
                disabled={!isStarted || isFinished || isWithdrawable || dioneBalance.balance.eq('0') || depositLoading}
              >{
                !isStarted
                  ? "Not Started Yet"
                  : dioneBalance.balance.eq('0')
                    ? "Insufficient Balance"
                    : (isFinished || isWithdrawable)
                      ? "Finished"
                      : depositLoading
                        ? "Staking..."
                        : "Select Additional Amount to Stake"
              }</ButtonPrimary>

              <ButtonPrimary
                onClick={HandleUnstakeStatus}
                disabled={!isStarted || withdrawLoading}
              >
                {withdrawLoading ? "Unstaking..." : isWithdrawable ? 'Unstake and Claim Rewards' : 'Unstake with Penalty'}
              </ButtonPrimary>
            </div>
          ) : (
            <div
              className="btn"
            >
              <ButtonPrimary
                onClick={handleStake}
                disabled={!isStarted || isFinished || isWithdrawable || dioneBalance.balance.eq('0') || depositLoading}
              >{
                !isStarted
                  ? "Not Started Yet"
                    : dioneBalance.balance.eq('0')
                      ? "Insufficient Balance"
                      : (isFinished || isWithdrawable)
                        ? "Finished"
                        : depositLoading
                          ? "Staking..."
                          : "Select Amount to Stake"
              }</ButtonPrimary>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
