import React from 'react';
import './styles/index.scss';
import Layout from './Layout/Layout';
import Main from './sections/Main';
import {usePollBlockNumber} from "./state/block/hooks";
import useEagerConnect from "./hooks/useEagerConnect";
import {useAccountEventListener} from "./hooks/useAccountEventListener";
import MulticallUpdater from './state/multicall/updater'
import {Toaster} from "react-hot-toast";


export function Updaters() {
  return (
    <>
      <MulticallUpdater />
    </>
  )
}

function App() {
  usePollBlockNumber()
  useEagerConnect()
  useAccountEventListener()

  return (
    <>
      <Toaster/>
      <Updaters/>
      <Layout>
        <Main />
      </Layout>
    </>
  );
}

export default App;
