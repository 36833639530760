import React, { createContext, useState, useEffect } from "react";

import './style.scss';

const BodyLock = () => {
    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
        document.body.style.overflowY = "visible";
        };
    }, []);

    return null;
};

export const Overlay = (props) => {
    return (
        <>
        <BodyLock />
        <div className="modal-overlay" role="presentation" {...props} />
        </>
    );
};


export const Context = createContext({
  isOpen: false,
  nodeId: "",
  modalNode: null,
  setModalNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  setCloseOnOverlayClick: () => true,
});

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalNode, setModalNode] = useState();
  const [nodeId, setNodeId] = useState("");
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);

  const handlePresent = (node, newNodeId) => {
    setModalNode(node);
    setIsOpen(true);
    setNodeId(newNodeId);
  };

  const handleDismiss = () => {
    setModalNode(undefined);
    setIsOpen(false);
    setNodeId("");
  };

  const handleOverlayDismiss = () => {
    if (closeOnOverlayClick) {
      handleDismiss();
    }
  };

  return (
    <Context.Provider
      value={{
        isOpen,
        nodeId,
        modalNode,
        setModalNode,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        setCloseOnOverlayClick,
      }}
    >
      {isOpen && (
        <div className="modal-wrapper">
          <Overlay onClick={handleOverlayDismiss} />
          {React.isValidElement(modalNode) &&
            React.cloneElement(modalNode, {
              onDismiss: handleDismiss,
            })}
        </div>
      )}
      {children}
    </Context.Provider>
  );
};

export default ModalProvider;
