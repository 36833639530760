import {useStakingContract} from "../useContract";
import {useTransaction} from "../useTransaction";
import {useMemo} from "react";
import {useAccount} from "wagmi";
import {useSingleCallResult} from "../../state/multicall/hooks";
import {AddressZero} from "@ethersproject/constants";

const messages = {
  depositSuccess: "Your Dione tokens are staked successfully!",
  depositFailed: "Staking failed! Please try again.",
  withdrawSuccess: "Withdrawal was successful!",
  withdrawFailed: "Couldn\'t withdraw tokens, please try again.",
}

export const useDeposit = (
  withWaiter = true
) => {
  const stakingContract = useStakingContract(true);
  const [loading, callback] = useTransaction(stakingContract, 'deposit')

  return useMemo(() => {
    return [
      loading,
      (args, overrideParams) => callback(
        args,
        overrideParams,
        withWaiter,
        messages.depositSuccess,
        messages.depositFailed
      )
    ]
  }, [loading, callback, withWaiter])
}

export const useWithdraw = (
  withWaiter = true
) => {
  const stakingContract = useStakingContract(true);
  const [loading, callback] = useTransaction(stakingContract, 'withdraw')

  return useMemo(() => {
    return [
      loading,
      (args, overrideParams) => callback(
        args,
        overrideParams,
        withWaiter,
        messages.withdrawSuccess,
        messages.withdrawFailed
      )
    ]
  }, [loading, callback, withWaiter])
}


export const useStakingStatus = () => {
  const stakingContract = useStakingContract(false);

  const isStarted = useSingleCallResult(stakingContract, 'isStarted', [])?.result?.[0];
  const isFinished = useSingleCallResult(stakingContract, 'isFinished', [])?.result?.[0];
  const isWithdrawable = useSingleCallResult(stakingContract, 'isWithdrawable', [])?.result?.[0];

  return useMemo(() => {
    return {
      isWithdrawable,
      isFinished,
      isStarted,
    }
  }, [isFinished, isStarted, isWithdrawable])
}

export const useUserInfo = () => {
  const { address: account } = useAccount();
  const stakingContract = useStakingContract(false);

  const userInfo = useSingleCallResult(stakingContract, 'userInfo', [account || AddressZero])?.result;
  const penalty = useSingleCallResult(stakingContract, 'getPenaltyAmount', [account || AddressZero])?.result?.[0];
  const pendingReward = useSingleCallResult(stakingContract, 'pendingReward', [account || AddressZero])?.result?.[0];
  const pendingPenalty = useSingleCallResult(stakingContract, 'pendingPenalty', [account || AddressZero])?.result?.[0]; 
  const penaltyTier = useSingleCallResult(stakingContract, 'getPenaltyTier', [account || AddressZero])?.result?.[0];

  return useMemo(() => {
    if(!account) return {
      userInfo: null,
      penalty: null,
      pendingReward: null,
      pendingPenalty: null,
      penaltyTier: null
    }

    return {
      userInfo,
      penalty,
      pendingReward,
      pendingPenalty,
      penaltyTier
    }
  }, [account, userInfo, penalty, pendingReward, pendingPenalty, penaltyTier])
}


