import './style.scss';
import WalletIcon from '../../assets/icons/connect.svg';
import {useAccount} from "wagmi";
import {useActiveChainId} from "../../hooks/useActiveChainId";

export default function Card({ item, value }) {
  const { address, isConnected } = useAccount();
  const { isWrongNetwork } = useActiveChainId()

  return (
    <div className="card">
      <div className="card_header">
        <div className="icon">
          <div>
            <img
              src={item.icon}
              alt="icon"
            />
          </div>
        </div>
        <div>{item.title}</div>
      </div>
      <div className="card_body">
        {isConnected && !isWrongNetwork ? null : (
          <div className="icon">
            <img
              src={WalletIcon}
              alt="icon"
            />
          </div>
        )}
        {isWrongNetwork ? "Wrong Network" : address ? value : 'Connect your wallet'}
      </div>
    </div>
  );
}
