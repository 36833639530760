import { useWeb3React } from '../wagmi'
import { useEffect, useRef } from 'react'
import { useProvider } from 'wagmi'
import { getChainId } from '../constants/chains'
import { useActiveChainId } from './useActiveChainId'
import { useSwitchNetworkLoading } from './useSwitchNetworkLoading'


export function useNetworkConnectorUpdater() {
  const { chainId } = useActiveChainId()
  const previousChainIdRef = useRef(chainId)
  const [loading] = useSwitchNetworkLoading()

  useEffect(() => {
    const setPrevChainId = () => {
      previousChainIdRef.current = chainId
    }
    if (loading) return setPrevChainId()
    const parsedQueryChainId = getChainId(1)

    if (!parsedQueryChainId && chainId === 1) return setPrevChainId()
    return setPrevChainId()
  }, [chainId, loading])
}

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const web3React = useWeb3React()
  const { chainId, isWrongNetwork } = useActiveChainId()
  const provider = useProvider({ chainId })

  return {
    provider,
    ...web3React,
    chainId,
    isWrongNetwork,
  }
}

export default useActiveWeb3React
