import {useCallback, useMemo, useState} from "react";
import {serializeError} from "eth-rpc-errors";

export const useLoadingCallback = (withError) => {
  const [loading, setLoading] = useState(false);

  const callback = useCallback(async (func, args = {}) => {
    setLoading(true);
    try {
      const res = await func(...args);
      setLoading(false);

      return res;
    } catch(e) {
      setLoading(false);

      if(withError) return serializeError(e)
      return false;
    }
  }, [setLoading, withError])

  return useMemo(() => [loading, callback], [loading, callback]);
}
