import {useCallback} from "react";
import useActiveWeb3React from "./useActiveWeb3React";
import {useAccount} from "wagmi";

export const useWaiter = () => {
  const { address, isConnected } = useAccount();
  const { provider } = useActiveWeb3React();

  return useCallback(async (response) => {
    if(!provider || !address || !isConnected) return false;

    if(response && 'hash' in response) {
      const { hash } = response;
      try {
        return provider.waitForTransaction(hash);
      } catch (e) {
        return false;
      }
    }

    return false;
  }, [address, provider, isConnected])
}
