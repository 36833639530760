/* eslint-disable no-param-reassign */
import { useEffect, useMemo } from 'react'
import { FormatTypes } from '@ethersproject/abi'
import useSWR, {
  // eslint-disable-next-line camelcase
  unstable_serialize,
} from 'swr'

export const FetchStatus = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  Fetched: 'FETCHED',
  Failed: 'FAILED',
}

export const fetchStatusMiddleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config)
    return Object.defineProperty(swr, 'status', {
      get() {
        let status = FetchStatus.Idle

        if (!swr.isValidating && !swr.error && !swr.data) {
          status = FetchStatus.Idle
        } else if (swr.isValidating && !swr.error && !swr.data) {
          status = FetchStatus.Fetching
        } else if (swr.data) {
          status = FetchStatus.Fetched
        } else if (swr.error && !swr.data) {
          status = FetchStatus.Failed
        }
        return status
      },
    })
  }
}

const getContractKey = (
  key,
) => {
  if (Array.isArray(key)) {
    const [contract, methodName, params] = key || []
    return {
      contract,
      methodName,
      params,
    }
  }
  return key
}

const serializesContractKey = (
  key,
) => {
  const { contract, methodName, params } = getContractKey(key) || {}
  const serializedKeys =
    key && contract && methodName
      ? {
        address: contract.address,
        interfaceFormat: contract.interface.format(FormatTypes.full),
        methodName,
        callData: contract.interface.encodeFunctionData(methodName, params),
      }
      : null
  return serializedKeys
}

/**
 * @example
 * const key = [contract, 'methodName', [params]]
 * const key = { contract, methodName, params }
 * const { data, error, mutate } = useSWRContract(key)
 */
export function useSWRContract(key, config = {}) {
  const { contract, methodName, params } = getContractKey(key) || {}
  const serializedKeys = useMemo(() => serializesContractKey(key), [key])

  return useSWR(
    serializedKeys,
      async () => {
        if (!contract || !methodName) return null
        if (!params) return contract[methodName]()
        return contract[methodName](...params)
      },
      config
  )
}

export const immutableMiddleware = (useSWRNext) => (key, fetcher, config) => {
  config.revalidateOnFocus = false
  config.revalidateIfStale = false
  config.revalidateOnReconnect = false
  return useSWRNext(key, fetcher, config)
}

export const localStorageMiddleware = (useSWRNext) => (key, fetcher, config) => {
  const swr = useSWRNext(key, fetcher, config)
  const { data } = swr
  const serializedKey = useMemo(() => unstable_serialize(key), [key])

  useEffect(() => {
    if (data) {
      try {
        const stringify = JSON.stringify(data)
        localStorage?.setItem(serializedKey, stringify)
      } catch (error) {
        //
      }
    }
  }, [data, serializedKey])

  let localStorageDataParsed

  if (!data && typeof window !== 'undefined') {
    const localStorageData = localStorage?.getItem(serializedKey)

    if (localStorageData) {
      try {
        localStorageDataParsed = JSON.parse(localStorageData)
      } catch (error) {
        localStorage?.removeItem(serializedKey)
      }
    }
  }

  return Object.defineProperty(swr, 'data', {
    value: data || localStorageDataParsed,
  })
}
