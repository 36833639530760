import {useCallback, useMemo} from "react";
import toast from 'react-hot-toast';
import useActiveWeb3React from "./useActiveWeb3React";
import {useWaiter} from "./useWaiter";
import {useLoadingCallback} from "./useLoadingCallback";
import {sendTransaction} from "../utils/txns";

export const defaultTransactionToasts = {
  SUCCESS: { title: "Submitted!", description: "Transaction submitted successfully!" },
  ERROR: { title: "Failed!", description: "Something went wrong" }
}

export const useTransaction = (
  contract,
  method
) => {
  const { account, provider: library, chainId } = useActiveWeb3React()
  const waiter = useWaiter()
  const [loading, loadingCallback] = useLoadingCallback()

  const responseCallback = useCallback(async (
    res,
    withWait = false,
    onSuccessMessage = defaultTransactionToasts.SUCCESS,
  ) => {
    if(withWait) {
      const result = await waiter(res);

      if(result) {
        toast.success(onSuccessMessage);
        return result;
      }

      return res;
    }

    toast.success(onSuccessMessage);
    return res;
  }, [waiter])

  const rejectCallback = useCallback(async (
    err,
    onErrorMessage = defaultTransactionToasts.ERROR
  ) => {
    if(err?.message) toast.error("Failed!" + " - " + err?.message)
    else toast.error(onErrorMessage);

    return err;
  }, [])

  const callback = useCallback(async (
    args = [],
    overrideParams = {},
    withWait = false,
    onSuccessMessage = defaultTransactionToasts.SUCCESS,
    onErrorMessage = defaultTransactionToasts.ERROR
  ) => {
    if(!account || !library || !chainId || !contract) return false;

    const res = await sendTransaction(
      contract,
      method,
      args,
      overrideParams,
      err => rejectCallback(err, onErrorMessage),
      response => responseCallback(response, withWait, onSuccessMessage)
    )

    return res || false;
  }, [account, contract, method, library, chainId, rejectCallback, responseCallback])

  return useMemo(() => [loading, (...params) => loadingCallback(callback, params)], [loading, loadingCallback, callback])
}
