import { Modal } from "../../context/modal";
import { useState } from "react";
import './style.scss';

const notesModalData = [
  'Dione Protocol is offering this staking program. We anticipate its end-date to align with the deployment of the Dione Blockchain.',
  'Staking Rewards:\nA.\tIn order to reward our long-term holders, we are offering a 1% per month (of total principle staked) for all holders who stake until the end of staking term.\nB\t100% of all early withdrawal penalties will be awarded to all long-term holders relative to how much they have staked; who successfully stake their Dione until the end of the staking term (migration to Dione blockchain)',
  'Tax Reimbursement: All holders who stake until the end of the staking term will also be eligible for an additional 2% tax reimbursement in the Dione Blockchain Native coin.\nAdditionally, all holders who remain staked for longer than the staking term will be eligible to partake in passive rewards obtained from early unstake penalties. All penalties from early unstaking will be added to a pool that will be disbursed to long-term stakers at a variable rate depending on the pool size and how much is staked per wallet.',
  'Auto-Migration: All staked Dione will be automatically migrated to the Dione Blockchain Native Coin and consolidated at a 100/1 with a 2% tax reimbursement as mentioned above. This means holders who stake will not need to transfer or migrate any Dione tokens into the native coin once the blockchain is deployed. The consolidation will preserve the value (w.r.t a stablecoin) of the tokens but removes additional zeros after the decimal for a cleaner aesthetic look',
  'Penalties: Early withdrawal penalties are as follows: \n•\tWithdraw under 1 month post-staking: 7% penalty and the holder will not get back the 2% tax. \n•\tWithdraw under 3 months post-staking: 6% penalty and the holder will not get back the 2% tax. \n•\tWithdraw anytime before we migrate to our own Blockchain but more than 3 months post-staking: 5% penalty and the holder will not get back the 2% tax.',
];


export const notesLocalStorageKey = 'notes'
export default function NotesModal({ onDismiss }) {
  const [dismiss, setDismiss] = useState(false);

  return (
    <Modal onDismiss={onDismiss} style={{ maxWidth: 890 }} title="Dione Staking Program">
      <div className="initial-modal">
        {notesModalData.map((item, index) => (
          <div
            className="initial-modal-item"
            key={index}
          >
            {item}
          </div>
        ))}
        <div
          className="initial-modal-item"
        >
          If you have additional questions about the Dione Staking Program, please visit our Staking Resource Center&nbsp;<a href="https://dione-protocol.gitbook.io/dione-staking-resource-center/" target="_blank" rel="noreferrer noopener">here</a>.
        </div>
        <div
          className="initial-modal-item"
        >
          You can check the audit report &nbsp;<a href={process.env.PUBLIC_URL + '/docs/audit.pdf'} target="_blank" rel="noreferrer noopener">here</a>.
        </div>
        <div className="initial-modal-title">
          Note: The penalty fees accrued in the penalty pool will be evenly disbursed amongst long-term stakers based on the % of Dione they have staked after the end of the staking term.
        </div>

        <label
              htmlFor="notes"
              className="checkbox-container"
            >
              <input
                type="checkbox"
                id="notes"
                name="notes"
                value="notes"
                checked={dismiss}
                onChange={() => {
                  setDismiss(d => {
                    localStorage.setItem(notesLocalStorageKey, !d ? 'checked' : '');
                    return !d;
                  });

                }}
              />
              Don't show again
              <span className="checkmark"/>
            </label>
      </div>
    </Modal>
  );
}
