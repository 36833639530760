import { useMemo } from 'react'

import { useTokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

function useTokenAllowance(token, owner, spender) {
  const contract = useTokenContract(token, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result?.[0]

  return useMemo(
    () => (token && allowance ? allowance : undefined),
    [token, allowance],
  )
}

export default useTokenAllowance
