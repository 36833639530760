import { Modal } from "../../context/modal";
import './style.scss';

import ButtonPrimary from '../ButtonPrimary';
import { ethers, BigNumber } from "ethers";
import { useState } from "react";
import {useApproveCallback} from "../../hooks/useApproveCallback";
import {getDioneAddress, getStakingAddress} from "../../utils/addressHelper";
import toast from "react-hot-toast";
import {useActiveChainId} from "../../hooks/useActiveChainId";

const BUY_MARGIN = BigNumber.from('1000');

export default function AmountModal({ onDismiss, onStake, balance }) {
  const [amount, setAmount] = useState(BigNumber.from(0));
  const [amountShow, setAmountShow] = useState('');
  const [selectedPercent, setSelectedPercent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { chainId } = useActiveChainId()

  const [approval, approveCallback] = useApproveCallback(amount, getDioneAddress(chainId), getStakingAddress(chainId));

  const applyPercent = (amount) => {
    let _percentAmount = BigNumber.from(0);
    if(balance.gt(BUY_MARGIN)) {
      _percentAmount = balance.mul(amount).div(100)

      if(amount === 100) {
        _percentAmount = _percentAmount.sub(BUY_MARGIN);
      }
    }
    setAmount(_percentAmount)
    setSelectedPercent(amount);
    setAmountShow(ethers.utils.formatUnits(_percentAmount, '9'));
  }

  const onType = (e) => {
    const value = e.target.value;
    setSelectedPercent(null);

    setAmountShow(value);
    setAmount(
      ethers.utils.parseUnits(value, '9')
    )
  }

  const handleStake = async () => {
    if(approval === 1) {
      await approveCallback()
      return;
    }

    if(approval === 2) {
      toast.error('Please wait for approval request');
      return;
    }

    if(amount.eq('0')) {
      toast.error('Invalid amount');
      return;
    }

    setLoading(true)
    await onStake([amount], {});
    setLoading(false);
    onDismiss()
  }

  return (
    <Modal onDismiss={onDismiss} style={{ maxWidth: 420 }} title="Stake Amount">
      <div className="amount-modal">
        <div className="amount-container">
          <input disabled={loading} className="amount-modal__input" value={amountShow} onChange={onType} placeholder='0.00' />

          <div className="amount-modal__btngp">
            <button disabled={loading} className={`amount-modal__state ${selectedPercent === 25 ? 'active' : ''}`} onClick={applyPercent.bind(this, 25)}><span>25%</span></button>
            <button disabled={loading} className={`amount-modal__state ${selectedPercent === 50 ? 'active' : ''}`} onClick={applyPercent.bind(this, 50)}><span>50%</span></button>
            <button disabled={loading} className={`amount-modal__state ${selectedPercent === 75 ? 'active' : ''}`} onClick={applyPercent.bind(this, 75)}><span>75%</span></button>
            <button disabled={loading} className={`amount-modal__state ${selectedPercent === 100 ? 'active' : ''}`} onClick={applyPercent.bind(this, 100)}><span>MAX</span></button>
          </div>
        </div>
        <div
          className="modal-btn"
        >
          <ButtonPrimary
            onClick={handleStake}
            disabled={loading || approval === 2}
            >
            {approval === 2
              ? "Approving..."
              : approval < 2
              ? "Approve"
                : loading ? 'Staking...'
                  : "Stake Dione"}
          </ButtonPrimary>
        </div>
      </div>
    </Modal>
  );
}
