import React from 'react'
import Close from '../../assets/icons/close.svg';

const Modal = ({
  title,
  onDismiss,
  children,
  minWidth = '320px',
  style = {},
  ...props
}) => {

  return (
    <div
      className={'modal'}
      style={{ minWidth, ...style }}
      {...props}
    >
      <div className="modal-header">
        <button
          className="close-btn"
          onClick={onDismiss}
        >
          <img
            src={Close}
            alt="close"
          />
        </button>
        {title && (
          <div className='modal-title'>{title}</div>
        )}
      </div>
      <div className="modal-body">{children}</div>
    </div>
  );
}

export default Modal
